<template>
    <div class="m-dialog-mask" @click.self="onBlur" v-show="visible">
        <div class="m-dialog">
            <div class="m-dialog-content">
                <div class="m-dialog-body">
                    <div class="form-group" v-show="isSafari">
                        <label id="audio_txt" >{{ $t('label_webpage_audio') }}</label>
                        <select id="audio" name="audio" @change="audioChange()">
                            <option value="0" id="audioOpen" selected="selected" >{{ $t('label_webpage_open') }}</option>
                            <option value="1" id="audioClose" >{{ $t('label_webpage_close') }}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label id="language_txt" >{{ $t('label_webpage_language') }}</label>
                        <select id="language" name="language" @change="languageChange()">
                            <option value="CN" selected="selected">简体中文</option>
                            <option value="TW" >繁体中文</option>
                            <option value="EN" >English</option>
                        </select>
                    </div>
                    <div class="form-group" v-show="isSafari">
                        <label id="frameRate_txt" >{{ $t('label_webpage_frameRate') }}</label>
                        <select id="frameRate" name="frameRate" @change="frameRateChange()">
                            <option value="15" >15 fps</option>
                            <option value="20">20 fps</option>
                            <option value="30" selected="selected">30 fps</option>
                            <option value="60">60 fps</option>
                        </select>
                    </div>
                    <div class="form-group" v-show="isSafari">
                        <label id="pixel_txt" >{{ $t('label_webpage_resolution') }}</label>
                        <select id="pixel" name="pixel" @change="pixelChange()">
                            <option value="1920x1080" >2560x1600</option>
                            <option value="1920x1080" selected="selected">1920x1080</option>
                            <option value="1280x720">1366x768</option>
                            <option value="1280x720">1280x720</option>
                            <option value="1280x720">1024x768</option>
                        </select>
                    </div>
                </div>
                <div class="m-dialog-footer" v-show="footer">
                    <button class="u-cancel" @click="onCancel">{{ cancelText }}</button>
                    <button class="u-confirm" @click="onConfirm">{{ okText }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import $ from 'jquery'
    export default {
        name: 'Dialog',
        props: {
            title: { // 标题
                type: String,
                default: '提示'
            },
            content: { // 内容
                type: String,
                default: ''
            },
            width: { // 宽度，默认640
                type: Number,
                default: 640
            },
            height: { // 高度，默认480
                type: Number,
                default: 480
            },
            switchFullscreen: { // 是否允许切换全屏（允许后右上角会出现一个按钮）
                type: Boolean,
                default: false
            },
            cancelText: { // 取消按钮文字
                type: String,
                default: '取消'
            },
            okText: { // 确认按钮文字
                type: String,
                default: '确定'
            },
            footer: { // 是否显示底部按钮，默认显示
                type: Boolean,
                default: true
            },
            visible: { // 对话框是否可见
                type: Boolean,
                default: false
            },
            isSafari: {
                type: Boolean,
                default: true
            }
        },
        data () {
            return {
                fullScreen: false,
            }
        },
        mounted() {
            $("#language").val(this.$i18n.locale);
        },
        computed: {
            dialogWidth () {
                if (this.fullScreen) {
                    return '100%'
                } else {
                    return this.width + 'px'
                }
            },
            dialogHeight () {
                if (this.fullScreen) {
                    return '100vh'
                } else {
                    return this.height + 'px'
                }
            }
        },
        watch: {
            visible (to) {
                if (to) {
                    this.fullScreen = false
                }
            }
        },
        methods: {
            onBlur () {
                this.$emit('close')
            },
            onFullScreen () {
                this.fullScreen = !this.fullScreen
            },
            onClose () {
                this.$emit('close')
            },
            onCancel () {
                this.$emit('cancel')
            },
            onConfirm () {
                this.$emit('ok')
            },

            frameRateChange (){
                console.log($("#language").val())
                this.$emit('frameRateChange',$("#language").val())
            },
            languageChange (){
                this.$i18n.locale = $("#language").val()
                // 要保存语言类型
                window.localStorage.setItem('lang',this.$i18n.locale)
                this.$emit('languageChange',$("#language").val())
            },
            audioChange (){
                this.$emit('audioChange',$("#audio").val())
            },
            pixelChange () {
                this.$emit('pixelChange',$("#audio").val())
            }
        }
    }
</script>
<style lang="less" scoped>
    label{
        width: 15%;
        font-size: 1vw;
    }
    select{
        position: absolute;
        right: 5%;
        background: rgba(255,255,255,0.39);
        border: 1px solid #9C9FAC;
        opacity: 1;
        border-radius: 5px;
        width: 50%;
        height: 4vh;
        font-size: 0.8vw;
    }
    .form-group label{
        text-align: left;
    }
    .form-group{
        padding: 0.8vh;
    }
    .modal-dialog{
        width: 25%;
        margin: 0;
        left: 71%;
        top: 68vh;
    }

    .m-dialog-mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        text-align: left;
        overflow: hidden;
        background: rgba(0,0,0,0.45);
        .m-dialog{
            position: absolute;
            width: 20%;
            right: 4%;
            bottom: 5%;
        }
        .m-dialog-content {
            position: relative;
            background: #fff;
            border-radius: 4px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
        }
        .m-dialog-body {
            padding: 10px;
            line-height: 1.5;
            word-wrap: break-word;
            overflow: auto;
            transition: all .3s;
        }
    }
</style>